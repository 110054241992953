export const TIPO_DE_TESTE_ABI = {
   distribuicaoDePotencia: 0x00,
   correnteDeFuga1A: 0x11,
   correnteDeFuga2A: 0x21,
   correnteDeFuga3A: 0x31,
   correnteDeFuga3B: 0x32,
   correnteDeFuga4A: 0x41,
   correnteDeFuga4B: 0x42,
   correnteDeFuga4C: 0x43,
   correnteDeFuga4D: 0x44,
};

export const TIPO_DE_TESTE_APE = {
   manometro: 0,
   vazamento: 2,
};

export const TIPO_DE_TESTE_ASE = {
   resistenciaDeTerra: 0,
   rede: 4,
   redeDeTensao: 5,
   redeDeCorrente: 6,
   resistenciaIsolacao: 1,
   correnteDeFugaManual: 2,
   correnteDeFugaNorma: 3,
};

export const TIPO_DE_TESTE_AVM = {
   manualDeVentilador: 0,
   manualDePressao: 1,
   normaDeVolume: 2,
   normaDePressao: 3,
   normaDeFiO2: 4,
};

export const TIPO_DE_TESTE_JAU = {
   energia: 0,
   sincronismo: 1,
   tempoDeCargaCapacitor: 2,
   ecgFrequencia: 3,
   ecgAmplitude: 4,
   arritmias_Performance: 5,
   respiracao_Frequencia: 6,
   respiracao_Amplitude: 7,
   respiracao_Apneia: 8,
};

export const TIPO_DE_TESTE_JAUMP = {
   frequenciaPPM: 0,
   amplitudeA: 1,
   larguraMS: 2,
   sensibilidadeMV: 3,
   periodoDeRefratarioMS: 4,
};

export const TIPO_DE_TESTE_SMP = {
   ecgFrequencia: 0,
   ecgAmplitude: 1,
   arritmias_Performance: 2,
   respiracao_Frequencia: 3,
   respiracao_Amplitude: 4,
   respiracao_Apneia: 5,
   temperatura: 6,
   pressaoInvasiva: 7,
   pressaoNaoInvasiva: 8,
   vazamento: 9,
   pressaoEstatica: 10,
   manometro: 11,
   spO2_Saturacao: 12,
   spO2_Freq: 13,
};

export const TIPO_DE_TESTE_SPK = {
   ecgFrequencia: 0,
   ecgAmplitude: 1,
   arritmias_Performance: 2,
   respiracao_Frequencia: 3,
   respiracao_Amplitude: 4,
   respiracao_Apneia: 5,
   temperatura: 6,
   pressaoInvasiva: 7,
   vazamento: 9,
   pressaoEstatica: 10,
   manometro: 11,
   spO2_Saturacao: 12,
   spO2_Freq: 13,
};