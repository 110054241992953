import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import ImportacaoDeTesteController from '../../controllers/Empresa/ImportacaoDeTesteController';
import { buildQueryString, numberToString, toEntityReference, updateState } from '../../utils/Functions';
import AnalisadorSimuladorView from './AnalisadorSimuladorView';
import Select from '../../components/Select';
import styled from 'styled-components';
import ListaPadraoOffline from '../ListaPadraoOffline';
import DecimalInput from '../../components/DecimalInput';
import { getUnidadesDeDesvio } from '../UnidadesDeDesvio';
import Document from '../../components/Document';
import CheckBox from '../../components/CheckBox';
import Button from '../../components/Button';
import { TIPO_DE_TESTE_ABI } from '../TiposDeTeste';
import { TIPO_DE_TESTE_APE } from '../TiposDeTeste';
import { TIPO_DE_TESTE_ASE } from '../TiposDeTeste';
import { TIPO_DE_TESTE_AVM } from '../TiposDeTeste';
import { TIPO_DE_TESTE_JAU } from '../TiposDeTeste';
import { TIPO_DE_TESTE_JAUMP } from '../TiposDeTeste';
import { TIPO_DE_TESTE_SMP } from '../TiposDeTeste';
import { TIPO_DE_TESTE_SPK } from '../TiposDeTeste';
import Line from '../../components/Line';
import { getUnidadesDeDesvioAVM } from '../UnidadesDeDesvioAVM';
import ClienteView from './ClienteView';
import EquipamentoDeClienteView from '../Cliente/EquipamentoDeClienteView';
import TextArea from '../../components/TextArea';
import IntegerInput from '../../components/IntegerInput';
import { showError, showInfo } from '../../components/Messages';
import DateInput from '../../components/DateInput';
import UsuarioDeEmpresaView from './UsuarioDeEmpresaView';

let LANG = {};

const STATUS_DA_TELA = {
   importandoTestes: 1,
   gerandoOrdemDeServico: 2,
};

export default class ImportacaoDeTesteView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         selecionados: new Map(),
         // arquivo: { id: 1163, nome: '1163.db', tipo: 'application/vnd.sqlite3', descricao: 'TIN100_02.db' },
         //arquivo: { id: 4833, nome: '4833.db', tipo: 'application/vnd.sqlite3', descricao: 'ASE100_00.sqlite' },
         statusDaTela: STATUS_DA_TELA.importandoTestes,
         itemSelecionado: null,
      };
      this.controller = new ImportacaoDeTesteController(this);
      LANG = props.lang;
   }

   componentDidMount() {
      this.props.api.get('/parametrodosistema/NumeroDeMesesDeValidadeDoCertificado').then((result) => {
         this.numeroDeMesesDeValidadeDoCertificado = result ? parseInt(result) : null;
      });
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let telaDeImportacaoDeTeste = lang.telaDeImportacaoDeTeste;
      let testes = this.getTestes();

      return (
         <React.Fragment>
            {this.state.statusDaTela === STATUS_DA_TELA.importandoTestes && (
               <React.Fragment>
                  <Row>
                     <Col sm={2} md={2} lg={2}>
                        <FormGroup>
                           <Label>{lang.codigo}</Label>
                           <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                        </FormGroup>
                     </Col>

                     <Col>
                        <FormGroup>
                           <Label>{telaDeImportacaoDeTeste.analisador}</Label>
                           <Select
                              name={'analisador'}
                              defaultValue={this.state.itemSelecionado.analisador}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) =>
                                 (i.sigla ? i.sigla : i.tipoDeEquipamento.sigla) + ' - ' + i.numeroDeSerie
                              }
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.analisador = i;
                                    if (i) {
                                       state.itemSelecionado.analisador.sigla =
                                          state.itemSelecionado.analisador.tipoDeEquipamento.sigla;
                                    }
                                 });
                              }}
                              formularioPadrao={(select) => {
                                 return (
                                    <AnalisadorSimuladorView
                                       api={this.props.api}
                                       select={select}
                                       lang={this.props.lang}
                                    />
                                 );
                              }}
                              noDropDown={true}
                              readOnlyColor='#ffff'
                              getFilterUrl={(text) =>
                                 '/analisador' + buildQueryString(7, null, 'id', { Searchable: text })
                              }
                           />
                        </FormGroup>
                     </Col>

                     <Col sm={3} md={3} lg={3}>
                        <FormGroup>
                           <Label>{telaDeImportacaoDeTeste.situacao}</Label>
                           <Select
                              name='situacao'
                              defaultValue={this.state.itemSelecionado.situacao}
                              options={[
                                 {
                                    id: 1,
                                    descricao: telaDeImportacaoDeTeste.situacaoDeDownloadDeTeste.cadastrada,
                                 },
                                 {
                                    id: 2,
                                    descricao: telaDeImportacaoDeTeste.situacaoDeDownloadDeTeste.processado,
                                 },
                                 {
                                    id: 3,
                                    descricao: telaDeImportacaoDeTeste.situacaoDeDownloadDeTeste.descartado,
                                 },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              allowEmpty={false}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.situacao = i;
                                 });
                              }}
                              nullText={''}
                           />
                        </FormGroup>
                     </Col>
                  </Row>

                  {this.state.itemSelecionado.analisador &&
                     (this.state.itemSelecionado.analisador.sigla === 'ABI100' ||
                        this.state.itemSelecionado.analisador.sigla === 'ABI200' ||
                        this.state.itemSelecionado.analisador.sigla === 'APE100' ||
                        this.state.itemSelecionado.analisador.sigla === 'ASE100' ||
                        this.state.itemSelecionado.analisador.sigla === 'AVM100' ||
                        this.state.itemSelecionado.analisador.sigla === 'JAU130' ||
                        this.state.itemSelecionado.analisador.sigla === 'JAU200') && (
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{telaDeImportacaoDeTeste.arquivoDeBancoDeDados}</Label>
                                 <Document
                                    lang={LANG}
                                    api={this.props.api}
                                    accept='.db, .sqlite'
                                    mimeType='application/vnd.sqlite3'
                                    defaultValue={this.state.arquivo}
                                    temporary={true}
                                    onChange={(file) => {
                                       updateState(this, (state) => {
                                          state.arquivo = file;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>

                           {this.state.arquivo && (
                              <Col sm={3} xs={3} md={3}>
                                 <FormGroup>
                                    <Label>&nbsp;</Label>
                                    <Button
                                       onClickAsync={this.importarEnsaios}
                                       text={telaDeImportacaoDeTeste.importar}
                                       inProgressText={telaDeImportacaoDeTeste.importando}
                                       //disabled={!this.state.arquivo}
                                    />
                                 </FormGroup>
                              </Col>
                           )}
                        </Row>
                     )}

                  <Row>
                     <Col sm={12} xs={6} md={4}>
                        <Button
                           onClick={() => {
                              let testes = this.getTestes(true);
                              let temTestesSelecionado = testes && testes.length > 0;

                              if (!temTestesSelecionado) {
                                 showError('Selecione pelo menos um teste para gerar a Ordem de Serviço.');
                                 return;
                              }

                              updateState(this, (state) => {
                                 state.statusDaTela = STATUS_DA_TELA.gerandoOrdemDeServico;
                                 state.itemSelecionado.numeroDeMesesDeValidadeDoCertificado =
                                    this.numeroDeMesesDeValidadeDoCertificado;
                              });
                           }}
                           text={telaDeImportacaoDeTeste.gerarOrdemDeServico}
                           //disabled={!this.state.arquivo}
                        />
                     </Col>
                  </Row>

                  {this.state.itemSelecionado.analisador && testes && testes.length > 0 && (
                     <StyledParametros>
                        <Row>
                           <Col style={{ paddingLeft: 6, paddingRight: 6 }}>
                              <ListaPadraoOffline
                                 esconderTitulo={true}
                                 esconderBotaoFechar={true}
                                 esconderConsulta={true}
                                 api={this.props.api}
                                 lang={LANG}
                                 getItens={() => testes}
                                 getRowStyle={(item, index) => {
                                    let rowStyle = {};
                                    if (this.state.selecionados && this.state.selecionados.get(index)) {
                                       rowStyle.backgroundColor = '#add8e657';
                                    }
                                    return rowStyle;
                                 }}
                                 getTitulosDaTabela={() => {
                                    return [
                                       {
                                          titulo: 'Ensaio',
                                          width: '5%',
                                       },
                                       {
                                          titulo: 'N°',
                                          width: '5%',
                                       },
                                       {
                                          titulo: LANG.telaDeImportacaoDeTeste.parametro,
                                          width: '20%',
                                       },
                                       {
                                          titulo: LANG.telaDeImportacaoDeTeste.resultado,
                                          width: '20%',
                                       },
                                       {
                                          titulo: LANG.telaDeImportacaoDeTeste.referencia,
                                          width: '14%',
                                       },
                                       {
                                          titulo: LANG.telaDeImportacaoDeTeste.desvioMaximoInferior,
                                          width: '13%',
                                       },
                                       {
                                          titulo: LANG.telaDeImportacaoDeTeste.desvioMaximoSuperior,
                                          width: '13%',
                                       },
                                       {
                                          titulo: LANG.telaDeImportacaoDeTeste.unidadeDeDesvio,
                                          width: '20%',
                                       },
                                       {
                                          titulo: (
                                             <div>
                                                <CheckBox
                                                   defaultChecked={this.state.selecionarTudo}
                                                   onChange={(checked) => {
                                                      let selecionados = this.state.selecionados;

                                                      let quantidadeDeTestes = this.getTestes().length;
                                                      for (let i = 0; i < quantidadeDeTestes; i++) {
                                                         selecionados.set(i, checked);
                                                      }

                                                      updateState(this, (state) => {
                                                         state.selecionarTudo = checked;
                                                         state.selecionados = null;
                                                      }).then(() => {
                                                         updateState(this, (state) => {
                                                            state.selecionados = selecionados;
                                                         });
                                                      });
                                                   }}
                                                />
                                             </div>
                                          ),
                                          width: 30,
                                       },
                                    ];
                                 }}
                                 getDadosDaTabela={(item, index) => {
                                    let campoUnidadeLimites = this.getControlesParaOCampoUnidadeLimites(index, item);
                                    let campoLimiteInferior = this.getControlesParaOCampoLimiteInferior(index, item);
                                    let campoLimiteSuperior = this.getControlesParaOCampoLimiteSuperior(index, item);

                                    return [
                                       <div style={{ display: 'flex', height: '100%' }}>
                                          <span
                                             className='no-wrap'
                                             style={{ marginTop: 8, color: 'blue', cursor: 'pointer' }}
                                             title='Selecionar'
                                             onClick={(checked) => {
                                                let selecionados = this.state.selecionados;

                                                let testes = this.getTestes();
                                                let quantidadeDeTestes = testes.length;
                                                for (let i = 0; i < quantidadeDeTestes; i++) {
                                                   if (item.testGroup === testes[i].testGroup) {
                                                      selecionados.set(i, true);
                                                   } else {
                                                      selecionados.set(i, false);
                                                   }
                                                }

                                                updateState(this, (state) => {
                                                   state.selecionarTudo = false;
                                                   state.selecionados = null;
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.selecionados = selecionados;
                                                   });
                                                });
                                             }}
                                          >
                                             {item.testGroup}
                                          </span>
                                       </div>,
                                       <div style={{ display: 'flex', height: '100%' }}>
                                          <span style={{ marginTop: 8 }}>{item.testNumber}</span>
                                       </div>,
                                       <div style={{ display: 'flex', height: '100%' }}>
                                          <span style={{ marginTop: 8 }}>{item.testTypeDesc}</span>
                                       </div>,
                                       this.getValorMedido(index, item),
                                       this.getControlesParaOCampoReferencia(index, item),
                                       campoLimiteInferior,
                                       campoLimiteSuperior,
                                       campoUnidadeLimites,
                                       <div>
                                          {this.state.selecionados && (
                                             <CheckBox
                                                defaultChecked={this.state.selecionados.get(index)}
                                                onChange={(checked) => {
                                                   updateState(this, (state) => {
                                                      state.selecionados.set(index, checked);
                                                   });
                                                }}
                                             />
                                          )}
                                       </div>,
                                    ];
                                 }}
                              />
                           </Col>
                        </Row>
                     </StyledParametros>
                  )}

                  <br />
                  <br />
               </React.Fragment>
            )}
            {this.state.statusDaTela === STATUS_DA_TELA.gerandoOrdemDeServico && this.gerarOrdemDeServico()}
         </React.Fragment>
      );
   };

   getTestes = (somenteSelecionados) => {
      let result = [];

      if (!this.state.itemSelecionado.analisador) return;

      if (
         this.state.itemSelecionado.analisador.sigla === 'ABI100' ||
         this.state.itemSelecionado.analisador.sigla === 'ABI200'
      ) {
         result = this.state.itemSelecionado.testesABI;
      } else if (this.state.itemSelecionado.analisador.sigla === 'APE100') {
         result = this.state.itemSelecionado.testesAPE;
      } else if (this.state.itemSelecionado.analisador.sigla === 'ASE100') {
         result = this.state.itemSelecionado.testesASE;
      } else if (this.state.itemSelecionado.analisador.sigla === 'AVM100') {
         result = this.state.itemSelecionado.testesAVM;
      } else if (this.state.itemSelecionado.analisador.sigla === 'SMP100') {
         result = this.state.itemSelecionado.testesSMP;
      } else if (
         this.state.itemSelecionado.analisador.sigla === 'JAU130' ||
         this.state.itemSelecionado.analisador.sigla === 'JAU200'
      ) {
         if (this.state.itemSelecionado.testesJAU && this.state.itemSelecionado.testesJAU.length > 0) {
            result = this.state.itemSelecionado.testesJAU;
         }

         if (this.state.itemSelecionado.testesJAUMP && this.state.itemSelecionado.testesJAUMP.length > 0) {
            result = this.state.itemSelecionado.testesJAUMP;
         }
      } else if (
         this.state.itemSelecionado.analisador.sigla === 'SPK100' ||
         this.state.itemSelecionado.analisador.sigla === 'SMP200'
      ) {
         result = this.state.itemSelecionado.testesSPK;
      }

      if (somenteSelecionados) {
         let resultTemp = [];
         for (var i = 0; i < result.length; i++) {
            if (this.state.selecionados.get(i)) {
               resultTemp.push(result[i]);
            }
         }
         result = resultTemp;
      }

      return result;
   };

   getValorMedido = (index, item) => {
      let result = '';

      if (
         (this.state.itemSelecionado.analisador.sigla === 'ABI100' ||
            this.state.itemSelecionado.analisador.sigla === 'ABI200') &&
         item.testType === TIPO_DE_TESTE_ABI.distribuicaoDePotencia
      ) {
         result = (
            <table className='table-no-border'>
               <tbody>
                  <tr>
                     <td className='no-wrap'>{'Potência (W): '}</td>
                     <td>{numberToString(item.tendency, 0, 3)}</td>
                  </tr>
                  <tr>
                     <td className='no-wrap'>{'Irms (mA): '}</td>
                     <td>{numberToString(item.irms, 0, 3)}</td>
                  </tr>
                  <tr>
                     <td className='no-wrap'>{'Carga (Ω): '}</td>
                     <td>{numberToString(item.resistiveLoad, 0, 3)}</td>
                  </tr>
                  <tr>
                     <td className='no-wrap'>{'Vpico (V): '}</td>
                     <td>{numberToString(item.vpeak, 0, 3)}</td>
                  </tr>
               </tbody>
            </table>
         );
      } else if (
         this.state.itemSelecionado.analisador.sigla === 'APE100'
      ) {
         result = (
            <Fragment>
               <TextInput defaultValue={item.measuredNumberValue + item.descUnidadeLimites} readOnly={true} />
            </Fragment>
         );
      } else if (this.state.itemSelecionado.analisador.sigla === 'ASE100') {
         result = (
            <table className='table-no-border'>
               <tbody>
                  {item.testType === TIPO_DE_TESTE_ASE.rede && (
                     <tr>
                        <td>{'Tipo'}</td>
                        <td>
                           <Select
                              defaultValue={item.tipoDeTesteDeRede}
                              options={[
                                 {
                                    id: 5,
                                    descricao: 'Tensão',
                                 },
                                 {
                                    id: 6,
                                    descricao: 'Corrente',
                                 },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.testesASE[index].tipoDeTesteDeRede = i ? i.id : null;
                                 });
                              }}
                              nullText={''}
                           />
                        </td>
                     </tr>
                  )}
                  <tr>
                     <td className='no-wrap'>{'Valor: '}</td>
                     <td>{item.measuredValueDesc}</td>
                  </tr>
                  <tr>
                     <td className='no-wrap'>{'MD1: '}</td>
                     <td>{item.descMedidor1}</td>
                  </tr>
                  <tr>
                     <td className='no-wrap'>{'MD2: '}</td>
                     <td>{item.descMedidor2}</td>
                  </tr>
               </tbody>
            </table>
         );
      } else if (
         this.state.itemSelecionado.analisador.sigla === 'AVM100' &&
         (item.testType === TIPO_DE_TESTE_AVM.normaDeVolume || item.testType === TIPO_DE_TESTE_AVM.normaDePressao)
      ) {
         result = (
            <Fragment>
               <TextInput defaultValue={item.measuredValueDesc1} readOnly={true} />
               <Line marginTop={2} marginBottom={2} />
               <TextInput defaultValue={item.measuredValueDesc2} readOnly={true} />
               <Line marginTop={2} marginBottom={2} />
               <TextInput defaultValue={item.measuredValueDesc3} readOnly={true} />
            </Fragment>
         );
      } else if (
         (this.state.itemSelecionado.analisador.sigla === 'JAU130' ||
            this.state.itemSelecionado.analisador.sigla === 'JAU200') &&
         (item.testType === TIPO_DE_TESTE_AVM.normaDeVolume || item.testType === TIPO_DE_TESTE_AVM.normaDePressao)
      ) {
         result = (
            <Fragment>
               <TextInput defaultValue={item.measuredValueDesc1} readOnly={true} />

               <Line marginTop={2} marginBottom={2} />

               <TextInput defaultValue={item.measuredValueDesc2} readOnly={true} />

               <Line marginTop={2} marginBottom={2} />

               <TextInput defaultValue={item.measuredValueDesc3} readOnly={true} />
            </Fragment>
         );
      } else if (
         this.state.itemSelecionado.analisador.sigla === 'SMP100' &&
         (item.testType === TIPO_DE_TESTE_AVM.normaDeVolume || item.testType === TIPO_DE_TESTE_AVM.normaDePressao)
      ) {
         result = (
            <Fragment>
               <TextInput defaultValue={item.measuredValueDesc1} readOnly={true} />

               <Line marginTop={2} marginBottom={2} />

               <TextInput defaultValue={item.measuredValueDesc2} readOnly={true} />

               <Line marginTop={2} marginBottom={2} />

               <TextInput defaultValue={item.measuredValueDesc3} readOnly={true} />
            </Fragment>
         );
      } else if (
         (this.state.itemSelecionado.analisador.sigla === 'SPK100' ||
            this.state.itemSelecionado.analisador.sigla === 'SMP200') &&
         (item.testType === TIPO_DE_TESTE_AVM.normaDeVolume || item.testType === TIPO_DE_TESTE_AVM.normaDePressao)
      ) {
         result = (
            <Fragment>
               <TextInput defaultValue={item.measuredValueDesc1} readOnly={true} />

               <Line marginTop={2} marginBottom={2} />

               <TextInput defaultValue={item.measuredValueDesc2} readOnly={true} />

               <Line marginTop={2} marginBottom={2} />

               <TextInput defaultValue={item.measuredValueDesc3} readOnly={true} />
            </Fragment>
         );
      } else {
         result = (
            <div>
               <TextInput defaultValue={item.measuredValueDesc} readOnly={true} />
            </div>
         );
      }

      return result;
   };

   getControlesParaOCampoReferencia = (index, item) => {
      let result = null;

      if (
         this.state.itemSelecionado.analisador.sigla === 'AVM100' &&
         (item.testType === TIPO_DE_TESTE_AVM.normaDeVolume || item.testType === TIPO_DE_TESTE_AVM.normaDePressao)
      ) {
         result = (
            <Fragment>
               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.referenceValue1}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].referenceValue1 = e.floatValue;
                     });
                  }}
               />

               <Line marginTop={2} marginBottom={2} />

               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.referenceValue2}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].referenceValue2 = e.floatValue;
                     });
                  }}
               />

               <Line marginTop={2} marginBottom={2} />

               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.referenceValue3}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].referenceValue3 = e.floatValue;
                     });
                  }}
               />
            </Fragment>
         );
      } else {
         result = (
            <DecimalInput
               decimalPlaces={0}
               defaultValue={this.getReferencia(item)}
               onChange={(e) => this.setReferencia(index, e.floatValue)}
            />
         );
      }

      return result;
   };

   getControlesParaOCampoLimiteInferior = (index, item) => {
      let result = null;

      if (
         this.state.itemSelecionado.analisador.sigla === 'AVM100' &&
         (item.testType === TIPO_DE_TESTE_AVM.normaDeVolume || item.testType === TIPO_DE_TESTE_AVM.normaDePressao)
      ) {
         result = (
            <Fragment>
               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.limiteInferior1}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].limiteInferior1 = e.floatValue;
                     });
                  }}
               />

               <Line marginTop={2} marginBottom={2} />

               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.limiteInferior2}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].limiteInferior2 = e.floatValue;
                     });
                  }}
               />

               <Line marginTop={2} marginBottom={2} />

               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.limiteInferior3}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].limiteInferior3 = e.floatValue;
                     });
                  }}
               />
            </Fragment>
         );
      } else if (this.state.itemSelecionado.analisador.sigla === 'ASE100') {
         if (
            item.testType === TIPO_DE_TESTE_ASE.resistenciaIsolacao ||
            item.testType === TIPO_DE_TESTE_ASE.rede ||
            item.testType === TIPO_DE_TESTE_ASE.redeDeCorrente ||
            item.testType === TIPO_DE_TESTE_ASE.redeDeTensao
         )
            result = (
               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.limiteInferior}
                  onChange={(e) => this.setLimiteInferior(index, e.floatValue)}
               />
            );
      } else {
         result = (
            <DecimalInput
               decimalPlaces={0}
               defaultValue={item.limiteInferior}
               onChange={(e) => this.setLimiteInferior(index, e.floatValue)}
            />
         );
      }

      return result;
   };

   getControlesParaOCampoLimiteSuperior = (index, item) => {
      let result = null;

      if (
         this.state.itemSelecionado.analisador.sigla === 'AVM100' &&
         (item.testType === TIPO_DE_TESTE_AVM.normaDeVolume || item.testType === TIPO_DE_TESTE_AVM.normaDePressao)
      ) {
         result = (
            <Fragment>
               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.limiteSuperior1}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].limiteSuperior1 = e.floatValue;
                     });
                  }}
               />

               <Line marginTop={2} marginBottom={2} />

               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.limiteSuperior2}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].limiteSuperior2 = e.floatValue;
                     });
                  }}
               />

               <Line marginTop={2} marginBottom={2} />

               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.limiteSuperior3}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].limiteSuperior3 = e.floatValue;
                     });
                  }}
               />
            </Fragment>
         );
      } else if (this.state.itemSelecionado.analisador.sigla === 'ASE100') {
         if (
            item.testType === TIPO_DE_TESTE_ASE.redeDeCorrente ||
            item.testType === TIPO_DE_TESTE_ASE.correnteDeFugaManual ||
            item.testType === TIPO_DE_TESTE_ASE.resistenciaDeTerra ||
            item.testType === TIPO_DE_TESTE_ASE.rede ||
            item.testType === TIPO_DE_TESTE_ASE.redeDeCorrente ||
            item.testType === TIPO_DE_TESTE_ASE.redeDeTensao
         ) {
            result = (
               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.limiteSuperior}
                  onChange={(e) => this.setLimiteSuperior(index, e.floatValue)}
               />
            );
         }
      } else {
         result = (
            <DecimalInput
               decimalPlaces={0}
               defaultValue={item.limiteSuperior}
               onChange={(e) => this.setLimiteSuperior(index, e.floatValue)}
            />
         );
      }

      return result;
   };

   getControlesParaOCampoUnidadeLimites = (index, item) => {
      let result = null;

      if (
         this.state.itemSelecionado.analisador.sigla === 'AVM100' &&
         (item.testType === TIPO_DE_TESTE_AVM.normaDeVolume || item.testType === TIPO_DE_TESTE_AVM.normaDePressao)
      ) {
         let unidadesDeDesvioDoTeste1 = getUnidadesDeDesvioAVM(this.props.lang, item, 1);
         let unidadesDeDesvioDoTeste2 = getUnidadesDeDesvioAVM(this.props.lang, item, 2);
         let unidadesDeDesvioDoTeste3 = getUnidadesDeDesvioAVM(this.props.lang, item, 3);
         result = unidadesDeDesvioDoTeste1 && (
            <Fragment>
               <div>
                  {unidadesDeDesvioDoTeste1 && (
                     <Select
                        defaultValue={item.unidadeLimites1}
                        options={unidadesDeDesvioDoTeste1}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        allowEmpty={false}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.testesAVM[index].unidadeLimites1 = i ? i.id : null;
                           });
                        }}
                     />
                  )}
               </div>

               <Line marginTop={2} marginBottom={2} />

               <div>
                  {unidadesDeDesvioDoTeste2 && (
                     <Select
                        defaultValue={item.unidadeLimites2}
                        options={unidadesDeDesvioDoTeste2}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        allowEmpty={false}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.testesAVM[index].unidadeLimites2 = i ? i.id : null;
                           });
                        }}
                     />
                  )}
               </div>

               <Line marginTop={2} marginBottom={2} />

               <div>
                  {unidadesDeDesvioDoTeste3 && (
                     <Select
                        defaultValue={item.unidadeLimites3}
                        options={unidadesDeDesvioDoTeste3}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        allowEmpty={false}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.testesAVM[index].unidadeLimites3 = i ? i.id : null;
                           });
                        }}
                     />
                  )}
               </div>
            </Fragment>
         );
      } else {
         let unidadesDeDesvioDoTeste = this.getUnidadesDeDesvioDoTeste(item);
         result = unidadesDeDesvioDoTeste && (
            <Select
               defaultValue={item.unidadeLimites}
               options={unidadesDeDesvioDoTeste}
               getKeyValue={(i) => i.id}
               getDescription={(i) => i.descricao}
               allowEmpty={false}
               onSelect={(i) => {
                  this.setUnidadeLimites(index, i ? i.id : null);
               }}
            />
         );
      }

      return result;
   };

   getUnidadesDeDesvioDoTeste = (item) => {
      let result = [];

      if (!this.state.itemSelecionado.analisador) return;

      result = getUnidadesDeDesvio(LANG, this.state.itemSelecionado.analisador.sigla, item);

      return result;
   };

   gerarOrdemDeServico = () => {
      let result = (
         <Fragment>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeImportacaoDeTeste.usuarioResponsavel}</Label>
                     <Select
                        name={'usuarioresponsavel'}
                        defaultValue={this.state.itemSelecionado.usuarioResponsavel}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.usuarioResponsavel = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <UsuarioDeEmpresaView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) =>
                           '/usuariodeempresa/fast' + buildQueryString(7, null, 'id', { Searchable: text })
                        }
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeImportacaoDeTeste.usuarioExecutor}</Label>
                     <Select
                        name={'usuarioexecutor'}
                        defaultValue={this.state.itemSelecionado.usuarioExecutor}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.usuarioExecutor = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <UsuarioDeEmpresaView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) =>
                           '/usuariodeempresa/fast' + buildQueryString(7, null, 'id', { Searchable: text })
                        }
                     />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeImportacaoDeTeste.cliente}</Label>
                     <Select
                        name={'cliente'}
                        defaultValue={this.state.itemSelecionado.cliente}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) =>
                           i.apelidoOuNomeFantasia ? i.apelidoOuNomeFantasia : i.nomeCompletoOuRazaoSocial
                        }
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.cliente = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <ClienteView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) => '/cliente/fast' + buildQueryString(7, null, 'id', { Searchable: text })}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               {this.state.itemSelecionado.cliente && this.state.itemSelecionado.cliente.id > 0 && (
                  <Col>
                     <FormGroup>
                        <Label>{this.props.lang.telaDeImportacaoDeTeste.equipamentoSobTeste}</Label>
                        <Select
                           name={'equipamentodecliente'}
                           defaultValue={this.state.itemSelecionado.equipamentoSobTeste}
                           getKeyValue={(i) => i.id}
                           getDescription={(i) =>
                              i.tipoDeEquipamento + ' / ' + i.numeroDeSerie + ' / ' + i.numeroDePatrimonio
                           }
                           onSelect={(i) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.equipamentoSobTeste = i;
                              });
                           }}
                           formularioPadrao={(select) => {
                              return (
                                 <EquipamentoDeClienteView
                                    api={this.props.api}
                                    select={select}
                                    cliente={this.state.itemSelecionado.cliente}
                                    lang={this.props.lang}
                                    filtroExtra={() => {
                                       return {
                                          idDoCliente: this.state.itemSelecionado.cliente
                                             ? this.state.itemSelecionado.cliente.id
                                             : null,
                                       };
                                    }}
                                 />
                              );
                           }}
                           noDropDown={true}
                           readOnlyColor='#ffff'
                           getFilterUrl={(text) =>
                              '/equipamentodecliente' +
                              buildQueryString(7, null, 'id', {
                                 Searchable: text,
                                 idDoCliente: this.state.itemSelecionado.cliente
                                    ? this.state.itemSelecionado.cliente.id
                                    : 2,
                              })
                           }
                        />
                     </FormGroup>
                  </Col>
               )}
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeImportacaoDeTeste.observacoes}</Label>
                     <TextArea
                        defaultValue={this.state.itemSelecionado.observacao}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.observacao = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeImportacaoDeTeste.localDoEnsaio}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.localDoEnsaio}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.localDoEnsaio = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeImportacaoDeTeste.umidade}</Label>
                     <IntegerInput
                        defaultValue={this.state.itemSelecionado.umidade}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.umidade = e.floatValue;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeImportacaoDeTeste.temperatura}</Label>
                     <IntegerInput
                        defaultValue={this.state.itemSelecionado.temperatura}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.temperatura = e.floatValue;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeImportacaoDeTeste.dataDoEnsaio}</Label>
                     <DateInput
                        defaultValue={this.state.itemSelecionado.dataDoEnsaio}
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.dataDoEnsaio = value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <Label>{this.props.lang.telaDeImportacaoDeTeste.numeroDeMesesDeValidadeDoCertificado}</Label>
                  <IntegerInput
                     defaultValue={this.state.itemSelecionado.numeroDeMesesDeValidadeDoCertificado}
                     onChange={(e) => {
                        updateState(this, (state) => {
                           state.itemSelecionado.numeroDeMesesDeValidadeDoCertificado = e.floatValue;
                        });
                     }}
                  />
               </Col>
            </Row>
            <Row>
               <Col sm={3} xs={3} md={3}>
                  <Button
                     onClickAsync={this.enviarOrdemDeServico}
                     text={this.props.lang.telaDeImportacaoDeTeste.gerarOrdemDeServico}
                     inProgressText={this.props.lang.telaDeImportacaoDeTeste.gerando}
                  />
               </Col>
               <Col sm={3} xs={3} md={3}>
                  <Button
                     onClick={() => this.setState({ statusDaTela: STATUS_DA_TELA.importandoTestes })}
                     text={this.props.lang.cancelar}
                  />
               </Col>
               <br />
               <br />
            </Row>
         </Fragment>
      );
      return result;
   };

   getReferencia = (item) => {
      if (!this.state.itemSelecionado.analisador) return;

      let result = null;

      if (
         this.state.itemSelecionado.analisador.sigla === 'ABI100' ||
         this.state.itemSelecionado.analisador.sigla === 'ABI200'
      ) {
         result = item.referencePower;
      } else if (this.state.itemSelecionado.analisador.sigla === 'APE100') {
         result = item.referenceValue;
      } else if (this.state.itemSelecionado.analisador.sigla === 'ASE100') {
         result = item.referenceValue;
      } else if (this.state.itemSelecionado.analisador.sigla === 'AVM100') {
         result = item.referenceValue;
      }

      return result;
   };

   setReferencia = (index, referenceValue) => {
      if (!this.state.itemSelecionado.analisador) return;

      updateState(this, (state) => {
         if (
            state.itemSelecionado.analisador.sigla === 'ABI100' ||
            state.itemSelecionado.analisador.sigla === 'ABI200'
         ) {
            state.itemSelecionado.testesABI[index].referencePower = referenceValue;
         } else if (state.itemSelecionado.analisador.sigla === 'APE100') {
            state.itemSelecionado.testesAPE[index].referenceValue = referenceValue;
         } else if (state.itemSelecionado.analisador.sigla === 'ASE100') {
            state.itemSelecionado.testesASE[index].referenceValue = referenceValue;
         } else if (state.itemSelecionado.analisador.sigla === 'AVM100') {
            state.itemSelecionado.testesAVM[index].referenceValue = referenceValue;
         }
      });
   };

   setLimiteSuperior = (index, limiteSuperior) => {
      if (!this.state.itemSelecionado.analisador) return;

      updateState(this, (state) => {
         if (
            state.itemSelecionado.analisador.sigla === 'ABI100' ||
            state.itemSelecionado.analisador.sigla === 'ABI200'
         ) {
            state.itemSelecionado.testesABI[index].limiteSuperior = limiteSuperior;
         } else if (state.itemSelecionado.analisador.sigla === 'APE100') {
            state.itemSelecionado.testesAPE[index].limiteSuperior = limiteSuperior;
         }
         if (state.itemSelecionado.analisador.sigla === 'ASE100') {
            state.itemSelecionado.testesASE[index].limiteSuperior = limiteSuperior;
         } else if (state.itemSelecionado.analisador.sigla === 'AVM100') {
            state.itemSelecionado.testesAVM[index].limiteSuperior = limiteSuperior;
         }
      });
   };

   setLimiteInferior = (index, limiteInferior) => {
      if (!this.state.itemSelecionado.analisador) return;

      updateState(this, (state) => {
         if (
            state.itemSelecionado.analisador.sigla === 'ABI100' ||
            state.itemSelecionado.analisador.sigla === 'ABI200'
         ) {
            state.itemSelecionado.testesABI[index].limiteInferior = limiteInferior;
         } else if (state.itemSelecionado.analisador.sigla === 'APE100') {
            state.itemSelecionado.testesAPE[index].limiteInferior = limiteInferior;
         }
         if (state.itemSelecionado.analisador.sigla === 'ASE100') {
            state.itemSelecionado.testesASE[index].limiteInferior = limiteInferior;
         } else if (state.itemSelecionado.analisador.sigla === 'AVM100') {
            state.itemSelecionado.testesAVM[index].limiteInferior = limiteInferior;
         }
      });
   };

   setUnidadeLimites = (index, unidadeLimites) => {
      if (!this.state.itemSelecionado.analisador) return;

      updateState(this, (state) => {
         if (
            state.itemSelecionado.analisador.sigla === 'ABI100' ||
            state.itemSelecionado.analisador.sigla === 'ABI200'
         ) {
            state.itemSelecionado.testesABI[index].unidadeLimites = unidadeLimites;
         } else if (state.itemSelecionado.analisador.sigla === 'APE100') {
            state.itemSelecionado.testesAPE[index].unidadeLimites = unidadeLimites;
         }
         if (state.itemSelecionado.analisador.sigla === 'ASE100') {
            state.itemSelecionado.testesASE[index].unidadeLimites = unidadeLimites;
         } else if (state.itemSelecionado.analisador.sigla === 'AVM100') {
            state.itemSelecionado.testesAVM[index].unidadeLimites = unidadeLimites;
         }
      });
   };

   importarEnsaios = () => {
      return new Promise((resolve, reject) => {
         if (
            this.state.itemSelecionado.analisador.sigla === 'ABI100' ||
            this.state.itemSelecionado.analisador.sigla === 'ABI200'
         ) {
            this.props.api
               .get('/downloaddeteste/baixartestesabi?fileId=' + this.state.arquivo.id)
               .then((result) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.testesABI = result;
                  });
                  resolve();
               })
               .catch(() => reject());
         } else if (this.state.itemSelecionado.analisador.sigla === 'APE100') {
            this.props.api
               .get('/downloaddeteste/baixartestesape?fileId=' + this.state.arquivo.id)
               .then((result) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.testesAPE = result;
                  });
                  resolve();
               })
               .catch(() => reject());
         } else if (this.state.itemSelecionado.analisador.sigla === 'ASE100') {
            this.props.api
               .get('/downloaddeteste/baixartestesase?fileId=' + this.state.arquivo.id)
               .then((result) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.testesASE = result;
                  });
                  resolve();
               })
               .catch(() => reject());
         } else if (this.state.itemSelecionado.analisador.sigla === 'AVM100') {
            this.props.api
               .get('/downloaddeteste/baixartestesavm?fileId=' + this.state.arquivo.id)
               .then((result) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.testesAVM = result;
                  });
                  resolve();
               })
               .catch(() => reject());
         } else if (
            this.state.itemSelecionado.analisador.sigla === 'JAU130' ||
            this.state.itemSelecionado.analisador.sigla === 'JAU200'
         ) {
            this.props.api
               .get('/downloaddeteste/baixartestesjau?fileId=' + this.state.arquivo.id)
               .then((result) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.testesJAU = result;
                  });
                  resolve();
               })
               .catch(() => reject());

            this.props.api
               .get('/downloaddeteste/baixartestesjaump?fileId=' + this.state.arquivo.id)
               .then((result) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.testesJAUMP = result;
                  });
                  resolve();
               })
               .catch(() => reject());
         } else {
            resolve();
         }
      });
   };

   enviarOrdemDeServico = () => {
      return new Promise((resolve, reject) => {
         let item = this.state.itemSelecionado;

         if (!item.localDoEnsaio) {
            showError(this.props.lang.telaDeImportacaoDeTeste.mensagens.informeOLocalDoEnsaio);
            reject();
            return;
         }
         if (!item.temperatura) {
            showError(this.props.lang.telaDeImportacaoDeTeste.mensagens.informeATemperatura);
            reject();
            return;
         }
         if (!item.umidade) {
            showError(this.props.lang.telaDeImportacaoDeTeste.mensagens.informeAUmidade);
            reject();
            return;
         }
         if (!item.dataDoEnsaio) {
            showError(this.props.lang.telaDeImportacaoDeTeste.mensagens.informeADataDoEnsaio);
            reject();
            return;
         }

         let input = {
            id: item.id,
            cliente: toEntityReference(item.cliente),
            equipamentoSobTeste: toEntityReference(item.equipamentoSobTeste),
            analisador: toEntityReference(item.analisador),
            usuarioExecutor: toEntityReference(item.usuarioExecutor),
            usuarioResponsavel: toEntityReference(item.usuarioResponsavel),
            temperatura: item.temperatura,
            umidade: item.umidade,
            localDoEnsaio: item.localDoEnsaio,
            observacao: item.observacao,
            dataDoEnsaio: item.dataDoEnsaio,
            numeroDeMesesDeValidadeDoCertificado: item.numeroDeMesesDeValidadeDoCertificado,
         };

         let testesSelecionados = this.getTestes(true);

         if (
            this.state.itemSelecionado.analisador.sigla === 'ABI100' ||
            this.state.itemSelecionado.analisador.sigla === 'ABI200'
         ) {
            input.testesABI = testesSelecionados;
         } else if (this.state.itemSelecionado.analisador.sigla === 'APE100') {
            input.testesAPE = testesSelecionados;
         } else if (this.state.itemSelecionado.analisador.sigla === 'ASE100') {
            input.testesASE = testesSelecionados;
         } else if (this.state.itemSelecionado.analisador.sigla === 'AVM100') {
            input.testesAVM = testesSelecionados;
         } else if (this.state.itemSelecionado.analisador.sigla === 'SMP100') {
            input.testesSMP = testesSelecionados;
         } else if (
            this.state.itemSelecionado.analisador.sigla === 'JAU130' ||
            this.state.itemSelecionado.analisador.sigla === 'JAU200'
         ) {
            if (this.state.itemSelecionado.testesJAU && this.state.itemSelecionado.testesJAU.length > 0) {
               input.testesJAU = testesSelecionados;
            }

            if (this.state.itemSelecionado.testesJAUMP && this.state.itemSelecionado.testesJAUMP.length > 0) {
               input.testesJAUMP = testesSelecionados;
            }
         } else if (
            this.state.itemSelecionado.analisador.sigla === 'SPK100' ||
            this.state.itemSelecionado.analisador.sigla === 'SMP200'
         ) {
            input.testesSPK = testesSelecionados;
         }

         this.props.api
            .post('/downloaddeteste/gerarordemdeservico', input)
            .then((result) => {
               showInfo('Ordem de Serviço ' + result + ' gerada com sucesso.').then(() => {
                  this.setState({ statusDaTela: STATUS_DA_TELA.importandoTestes });
                  resolve();
               });
            })
            .catch(reject);
      });
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeImportacaoDeTeste.titulo}
            url={'/downloaddeteste'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id'}
            maxWidth={'100%'}
            permissoes={[1461, 1462, 1463, 1464]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            ref={(c) => (this.form = c)}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               parametrosInformados: [],
               situacao: { id: 1 },
               //analisador: {
               //   id: 124,
               //   numeroDeSerie: 'XXX',
               //   sigla: 'ASE100',
               //},
            }}
         />
      );
   }
}

const StyledParametros = styled.div`
   .div-tabela-formulario-padrao {
      overflow-y: hidden;
   }

   .div-cabecalho-tabela-formulario-padrao {
      overflow-y: hidden;
   }
`;
