import { getTiposDeUnidadeDeDesvioASE_RedeDeTensao } from './Descricoes';
import { TIPO_DE_TESTE_ASE } from './TiposDeTeste';

export const getUnidadesDeDesvioJAU = (lang, teste) => {
   let result = [];
   switch (teste.testType) {
      case TIPO_DE_TESTE_ASE.redeDeTensao:
         result = getTiposDeUnidadeDeDesvioASE_RedeDeTensao(lang);
         break;

      case TIPO_DE_TESTE_ASE.correnteDeFugaManual:
         result = null;
         break;

      case TIPO_DE_TESTE_ASE.correnteDeFugaNorma:
         result = null;
         break;

      case TIPO_DE_TESTE_ASE.redeDeCorrente:
         result = null;
         break;

      case TIPO_DE_TESTE_ASE.resistenciaDeTerra:
         result = null;
         break;

      case TIPO_DE_TESTE_ASE.resistenciaIsolacao:
         result = null;
         break;

      default:
         break;
   }
   return result;
};
