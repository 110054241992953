import React, { Component } from 'react';
import { LayoutParams } from '../config/LayoutParams';
import '../contents/css/cabecalho-tabela-formulario-padrao.css';
import '../contents/css/tabela-formulario-padrao.css';
import Button from '../components/Button';

export default class ListaPadraoOffline extends Component {
   constructor(props) {
      super(props);
      const { renderizarFiltros, getTitulosDaTabela, getDadosDaTabela } = props;

      this.state = {};

      // this.setState = props.setFormState;
      // this.getState = props.getFormState;

      this.formConsultaSubmit = this.formConsultaSubmit.bind(this);
      this.renderizarCodigo = this.renderizarCodigo.bind(this);
      this.getTitulo = this.getTitulo.bind(this);
      this.getLista = this.getLista.bind(this);
      this.getNavegador = this.getNavegador.bind(this);
      this.cancelarClick = this.cancelarClick.bind(this);
      this.filtrar = this.filtrar.bind(this);
      this.getCabecalhos = this.getCabecalhos.bind(this);
      this.renderizarFiltros = renderizarFiltros ? renderizarFiltros : this.renderizarFiltros;
      this.getTitulosDaTabela = getTitulosDaTabela ? getTitulosDaTabela : this.getTitulosDaTabela;
      this.getDadosDaTabela = getDadosDaTabela ? getDadosDaTabela : this.getDadosDaTabela;
   }

   getState = () => this.state;

   componentDidMount() {
      try {
         if (this.props.ref) {
            this.props.ref(this);
         }

         //let clientHeight = document.getElementById('listaPadrao').clientHeight;

         let state = this.getState();

         state.itemSelecionado = null;
         state.itens = null;
         state.ordenacao = this.props.ordenacaoPadrao;
         state.tamanhoDaPagina = null;

         this.setState(state, () => {
            if (!this.props.iniciarVazio) {
               this.filtrar();
            }
         });
      } catch (e) {
         console.error(e);
      }
   }

   /*
         Esse formulário padrão é um componente renderizado na tela.
         Para utilizado implemente os métodos a seguir e passe eles como propriedade no componente.
         Por exemplo:

         <ListaPadrao 
            getItens={() => [ 
               { id: 1, descricao: 'Item 1' }, 
               { id: 2, descricao: 'Item 2' }, 
               { id: 3, descricao: 'Item 3' }, 
            ]}
            getTitulosDaTabela={this.getTitulosDaTabela}
            getDadosDaTabela={this.getDadosDaTabela}   
         />      
    */

   renderizarFiltros(sender) {
      // * OBGRIGATÓRIO
      // Implemente esse método para desenhar os campos de filtro na tela
   }

   getTitulosDaTabela() {
      // * OBGRIGATÓRIO
      // Implemente esse método no para retornar um array de string com os títulos para a tabela
   }

   getDadosDaTabela(item, index) {
      // * OBGRIGATÓRIO
      // Implemente esse método no para retornar um array de valores a ser usado na linha da tabela.
      // Este método é chamado passando registro por registro.
   }

   formConsultaSubmit(event) {
      event.preventDefault();
      this.filtrar();
   }

   isMobile = () => {
      return window.screen.width <= 600;
   };

   getNavegador() {
      return (
         <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '10px 12px 10px 10px' }}>
            <div style={{ width: 150, textAlign: 'left' }}>
               {!this.props.esconderBotaoFechar && (
                  <Button
                     variant='secondary'
                     onClick={this.cancelarClick}
                     style={{ width: 150 }}
                     text={this.props.lang.formularioPadrao.fechar}
                  />
               )}
            </div>
         </div>
      );
   }

   onRowClick = (item, index) => {
      if (this.props.select) {
         this.props.select.aoSelecionar(item);
      }
   };

   handleClick = (item, index) => {
      if (this.props.onRowClick) {
         this.props.onRowClick(item, index);
      }
   };

   cancelarClick() {
      return new Promise((resolve) => {
         if (this.props.select && this.props.select.aoCancelar) {
            this.props.select.aoCancelar();
         }
         if (this.props.aoCancelar) {
            this.props.aoCancelar();
         }
         if (this.props.aoFechar) {
            this.props.aoFechar();
         }
         resolve();
      });
   }

   filtrar() {
      return new Promise((resolve, reject) => {
         try {
            this.setState(
               {
                  itens: this.props.getItens(),
               },
               () => resolve()
            );
         } catch (e) {
            reject(e);
         }
      });
   }

   renderizarCodigo(codigo) {
      return (
         <td className='codigo'>
            <div>{codigo}</div>
         </td>
      );
   }

   getTitulo() {
      return (
         <div
            style={{
               backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
               color: LayoutParams.colors.corSecundaria,
               borderRadius: 0,
               marginLeft: 0,
               marginRight: 0,
            }}
         >
            <div style={{ paddingTop: 0, paddingLeft: 10, paddingRight: 10, height: 45, display: 'flex' }}>
               <div
                  style={{
                     display: 'table-cell',
                     width: '0',
                     overflowX: 'visible',
                     fontSize: 30,
                     fontWeight: 500,
                     whiteSpace: 'nowrap',
                  }}
               >
                  <div>{this.props.titulo}</div>
               </div>
            </div>
         </div>
      );
   }

   getFiltros() {
      return (
         <form
            style={{
               paddingTop: 8,
               paddingLeft: 10,
               paddingRight: 14,
            }}
            onSubmit={this.formConsultaSubmit}
            action='/'
            name='formConsulta'
            id='formConsulta'
         >
            {this.renderizarFiltros(this)}
         </form>
      );
   }

   getCabecalhos() {
      const isMobile = this.isMobile();

      var titulos = this.getTitulosDaTabela && this.getTitulosDaTabela();
      if (!titulos || isMobile) {
         return null;
      }
      return (
         <div className='div-cabecalho-tabela-formulario-padrao'>
            <table className='cabecalho-tabela-formulario-padrao'>
               <thead>
                  <tr>
                     {titulos.map((item, index) => {
                        return (
                           <td key={index} className={item.className} style={{ width: item.width }}>
                              {item.titulo}
                           </td>
                        );
                     })}
                  </tr>
               </thead>
            </table>
         </div>
      );
   }

   getLista() {
      const isMobile = this.isMobile();
      const titulos = this.getTitulosDaTabela && this.getTitulosDaTabela();
      const textoDosTitulos = titulos.map((i) => i.titulo);
      let result = null;

      if (!titulos) {
         return null;
      }

      const tamanhos = titulos.map((i) => i.width);
      const classes = titulos.map((i) => i.className);
      let itens = this.getState().itens;

      if (isMobile) {
         result =
            !itens || (itens && itens.length === 0) ? (
               <table className='tabela-formulario-padrao'>
                  <tbody>
                     <tr>
                        <td style={{ width: '100%', textAlign: 'center' }}>
                           <span>{this.props.lang.formularioPadrao.mensagens.nenhumRegistroEncontrado}</span>
                        </td>
                     </tr>
                  </tbody>
               </table>
            ) : (
               (result = itens.map((item, rowIndex) => {
                  return !item ? null : (
                     <div
                        key={rowIndex}
                        className={this.props.select ? 'noselect' : null}
                        style={{
                           cursor: this.props.select || this.props.onRowClick ? 'pointer' : 'default',
                           border: '1px solid #999',
                           borderRadius: 5,
                           marginBottom: 7,
                           marginRight: 10,
                           padding: 3,
                        }}
                        onClick={() => this.handleClick(item, rowIndex)}
                     >
                        {this.getDadosDaTabela(item, rowIndex).map((dado, campoIndex) => {
                           return (
                              <div key={campoIndex} style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ display: 'table-cell' }}>
                                    <strong>{textoDosTitulos[campoIndex]}:&nbsp;</strong>
                                 </div>
                                 <div style={{ display: 'table-cell' }}>
                                    <span style={{ wordWrap: 'anywhere' }}>{dado}</span>
                                 </div>
                              </div>
                           );
                        })}
                     </div>
                  );
               }))
            );
      } else {
         result =
            itens != null && itens && itens.length === 0 ? (
               <table className='tabela-formulario-padrao'>
                  <tbody>
                     <tr>
                        <td style={{ width: '100%', textAlign: 'center' }}>
                           <span>{this.props.lang.formularioPadrao.mensagens.nenhumRegistroEncontrado}</span>
                        </td>
                     </tr>
                  </tbody>
               </table>
            ) : (
               <table className='tabela-formulario-padrao'>
                  <tbody>
                     {itens != null &&
                        itens.map((item, rowIndex) => {
                           if (!item) {
                              return null;
                           } else {
                              let defaultRowStyle = {
                                 cursor: this.props.select || this.props.onRowClick ? 'pointer' : 'default',
                                 backgroundColor:
                                    this.props.highlightIndex === rowIndex
                                       ? LayoutParams.colors.tableItemhHighlightBackgroundColor
                                       : null,
                              };

                              let customRowStyle = {};
                              if (this.props.getRowStyle) {
                                 customRowStyle = this.props.getRowStyle(item, rowIndex);
                              }

                              return (
                                 <tr
                                    key={rowIndex}
                                    className={this.props.select ? 'noselect' : null}
                                    style={{ ...defaultRowStyle, ...(customRowStyle ? customRowStyle : {}) }}
                                    onClick={() => this.handleClick(item, rowIndex)}
                                 >
                                    {[
                                       this.getDadosDaTabela(item, rowIndex).map((dado, campoIndex) => {
                                          return (
                                             <td
                                                key={campoIndex}
                                                className={classes[campoIndex]}
                                                style={{ width: tamanhos[campoIndex] }}
                                             >
                                                {dado}
                                             </td>
                                          );
                                       }),
                                    ]}
                                 </tr>
                              );
                           }
                        })}
                  </tbody>
               </table>
            );
      }
      return result;
   }

   getAcoes = () => {
      return (
         !this.props.esconderConsulta && (
            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10, paddingLeft: 10 }}>
               <div style={{ display: 'table-cell' }}>
                  <Button
                     style={{ width: 200 }}
                     text={this.props.lang.pesquisar}
                     inProgressText={this.props.lang.pesquisando}
                     onClickAsync={this.filtrar}
                  />
               </div>
            </div>
         )
      );
   };

   render() {
      return (
         <div
            id='listaPadrao'
            style={{
               display: 'flex',
               flexDirection: 'column',
               maxHeight: '100%',
               overflowX: 'hidden',
               width: '100%',
               maxWidth: '100%',
               height: '100%',
            }}
         >
            {!this.props.esconderTitulo && this.getTitulo()}
            {this.getFiltros()}
            {this.getAcoes()}
            {this.getCabecalhos()}
            <div className='div-tabela-formulario-padrao'>{this.getLista()}</div>
            {this.getNavegador()}
         </div>
      );
   }
}
