import { getUnidadesDeDesvioABI } from './UnidadesDeDesvioABI';
import { getUnidadesDeDesvioAPE } from './UnidadesDeDesvioAPE';
import { getUnidadesDeDesvioASE } from './UnidadesDeDesvioASE';
import { getUnidadesDeDesvioAVM } from './UnidadesDeDesvioAVM';
import { getUnidadesDeDesvioJAU } from './UnidadesDeDesvioJAU';
import { getUnidadesDeDesvioSMP } from './UnidadesDeDesvioSMP';
import { getUnidadesDeDesvioSPK } from './UnidadesDeDesvioSPK';

export const getUnidadesDeDesvio = (lang, sigla, teste) => {
   let result = [];
   if (sigla === 'ABI100' || sigla === 'ABI200')  {
      result = getUnidadesDeDesvioABI(lang, teste);
   } else if (sigla === 'APE100') {
      result = getUnidadesDeDesvioAPE(lang, teste);
   } else if (sigla === 'ASE100') {
      result = getUnidadesDeDesvioASE(lang, teste);
   } else if (sigla === 'AVM100') {
      result = getUnidadesDeDesvioAVM(lang, teste);
   } else if (sigla === 'JAU130' || sigla === 'JAU200') {
      result = getUnidadesDeDesvioJAU(lang, teste);
   } else if (sigla === 'SMP100') {
      result = getUnidadesDeDesvioSMP(lang, teste);
   } else if (sigla === 'SPK100' || sigla === 'SMP200') {
      result = getUnidadesDeDesvioSPK(lang, teste);
   }
   return result;
};
