import { getTiposDeUnidadeDesvioABI } from './Descricoes';
import { TIPO_DE_TESTE_ABI } from './TiposDeTeste';

export const getUnidadesDeDesvioABI = (lang, teste) => {
   let result = [];
   switch (teste.testType) {
      case TIPO_DE_TESTE_ABI.distribuicaoDePotencia:
         result = getTiposDeUnidadeDesvioABI(lang);
         break;

      default:
         break;
   }
   return result;
};
